import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    courses: {},
    error: null,
    approvalStatus: {
        department: '',
        faculty: '',
    },
};

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        createCourses: (state, action) => {
            state.courses = action.payload;
        },
        SAVE_APPROVAL_STATUS: (state, action) => {
            state.approvalStatus = action.payload;
        },
    },
});

export const { createCourses, GET_AVAILABLE_COURSES, SAVE_APPROVAL_STATUS, LOADING, STOP_LOADING } =
    coursesSlice.actions;

export default coursesSlice.reducer;
