import React, { Suspense } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { Spinner } from '../components';
import { protectedRoutes } from '../routes';
import AuthGuard from '../routes/authGuard';

const Content = () => {
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route element={<AuthGuard />}>
                    {protectedRoutes.map((route, idx) => {
                        return (
                            route.path && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={route.path === '/' ? <Navigate to="/dashboard" replace /> : route.element}
                                />
                            )
                        );
                    })}
                </Route>
            </Routes>
        </Suspense>
    );
};

export default Content;
