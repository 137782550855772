import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CautionIcon } from '../../../assets/icons/caution.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
        padding: 4rem 3.2rem;
        /* width: 36.6rem; */
    }
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const DeletePopup = ({ title, subtitle, onConfirm, onCancel, confirmText, show, close }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="rounded-[16px] flex flex-col gap-[1rem]">
                <div className="flex items-center justify-center md:justify-between">
                    <div className="flex items-center  gap-[.5rem]">
                        <CautionIcon />
                        <Text size="1.9rem" weight="600" color="#1F2937">
                            {title}
                        </Text>
                    </div>
                    <StyledClose onClick={onCancel} />
                </div>
                <Text align="left" size="1.6rem" weight="400" color="#374151">
                    {subtitle}
                </Text>
                <div className="my-[1rem] flex items-center md:justify-end justify-center gap-[1rem]">
                    <Button onClick={onCancel} color="#1F2937" className="border border-[#D1D5DB]">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} color="#FFFFFF" bgColor="#EF4444">
                        {confirmText}
                    </Button>
                </div>
            </div>
        </StyledModal>
    );
};

export default DeletePopup;
