import { styled } from 'twin.macro';

export const PageTitle = styled.h1`
    font-size: ${({ size }) => size || '2.3rem'};
    font-weight: ${({ weight }) => weight || '600'};
    color: ${({ color }) => color || '#1f2937'};
    line-height: ${({ lineHeight }) => lineHeight || '3.4rem'};
    text-align: ${({ align }) => align || 'center'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    text-wrap: ${({ textWrap }) => textWrap || 'nowrap'};

    @media screen and (max-width: 768px) {
        /* Adjust styles for screens with a width up to 768 pixels */
        font-size: ${({ mobileSize }) => mobileSize || '1.4rem'};
        text-wrap: ${({ mobileTextWrap }) => mobileTextWrap || 'wrap'};
    }
`;

export const Text = styled.p`
    font-size: ${({ size }) => size || '1.4rem'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#1f2937'};
    line-height: ${({ lineHeight }) => lineHeight || '2.8rem'};
    text-align: ${({ align }) => align || 'center'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    margin-left: ${({ left }) => left || null};
    margin-right: ${({ right }) => right || null};
    border-bottom: ${({ borderBottom }) => borderBottom || null};
    border-style: ${({ borderStyle }) => borderStyle || null};
    border-color: ${({ borderColor }) => borderColor || null};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || null};
    text-transform: ${({ transform }) => transform || 'normal'};
    text-wrap: ${({ textWrap }) => textWrap || 'wrap'};

    @media screen and (max-width: 768px) {
        /* Adjust styles for screens with a width up to 768 pixels */
        font-size: ${({ mobileSize }) => mobileSize || '1.2rem'};
        text-wrap: ${({ mobileTextWrap }) => mobileTextWrap || 'wrap'};
        line-height: ${({ mobileLineHeight }) => mobileLineHeight || '1.7rem'};
    }
`;
