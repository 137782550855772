import React from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { navLinks } from './navLinks';

const Sidebar = ({ open }) => {
    const location = useLocation();
    const { student } = useSelector((store) => store.student);
    const isStudentVerified = student.status === 'Accepted' || !student.isAcceptanceFee;
    const linksToMap = isStudentVerified ? navLinks : [];

    return (
        <div className="relative">
            <aside
                className={`${
                    open ? 'lg:w-[25rem] px-[2.4rem]' : 'w-[8.4rem] px-[.6rem]'
                } fixed z-[1000] duration-300 md:block hidden h-screen pb-[5rem] inset-y-0 left-0 bg-blue-sidenav overflow-y-scroll scrollbar-hide`}
            >
                <div className="flex items-center gap-[1rem] pt-[3.1rem] pb-[3.8rem]">
                    {student?.organizationLogo ? (
                        <img
                            src={student?.organizationLogo}
                            alt="logo"
                            className="h-[4.8rem] w-[4.8rem] rounded-full"
                        />
                    ) : (
                        // <h1
                        //     className={`text-blue-sidenav items-center flex justify-center rounded-full w-[2.5rem] bg-white text-[2rem] text-center ml-[13.32px] font-semibold`}
                        // >
                        //     {student?.organizationName.charAt(0)}
                        // </h1>
                        ''
                    )}
                    <h1 className={`text-white text-[1.4rem] font-semibold ${!open && 'scale-0'}`}>
                        {student?.organizationName}
                    </h1>
                </div>
                <ul className="text-light-ash-bg">
                    {linksToMap?.map((link, index) => {
                        const isActive =
                            location.pathname === link?.to ||
                            (location.pathname.startsWith(link?.to) && link?.to !== '/');
                        return (
                            <Link to={link?.to} key={index}>
                                <li
                                    className={`font-medium text-[1.4rem] mt-[5px] leading-6 flex items-center gap-x-4 cursor-pointer ${
                                        isActive ? 'bg-white' : ''
                                    } hover:bg-[#4F46E5] focus:bg-white rounded-[8px] px-[1.315rem] pt-[13.98px] pb-[14.15px]`}
                                >
                                    {isActive ? link.active : link?.src}
                                    <span
                                        className={`${!open && 'hidden'} ${
                                            isActive ? 'text-[#1F2937]' : 'text-light-ash-bg'
                                        } `}
                                    >
                                        {link?.title}
                                    </span>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            </aside>
        </div>
    );
};

export default Sidebar;
