import React from 'react';

import tw from 'twin.macro';

const ItemContainer = tw.ul`
  flex
  flex-col
  gap-[1rem]
  text-[1.6rem]
  leading-[2.4rem]
  font-normal
  text-[#1F2937]
  px-[8px]
  py-[12px]
  mb-0
`;

const Item = tw.li`
  flex
  items-center
  gap-[0.8rem]
  hover:bg-[#F3F4F6]
  rounded-[8px]
  py-[1.115rem]
  px-[1.715rem]
`;

const ActionsPopup = ({ open, items, close, className }) => {
    return (
        <div>
            {open && <div className="fixed z-10 inset-0" onClick={close}></div>}
            <div
                className={`${
                    open
                        ? 'visible absolute shadow-lg z-[150] min-w-[max-content] bg-white right-0 top-[50px] cursor-pointer'
                        : 'hidden'
                }`}
            >
                <ItemContainer className={className}>
                    {items
                        ?.filter((el) => !el.notShown)
                        ?.map((item, index) => (
                            <Item key={index.toString()} onClick={item.click}>
                                {item.icon && item.icon}
                                {item.name}
                            </Item>
                        ))}
                </ItemContainer>
            </div>
        </div>
    );
};

export default ActionsPopup;
