import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow } from '../../containers/ScreenContainers';

export const GoBack = ({ title, children, subTitle } = {}) => {
    const navigate = useNavigate();
    return (
        <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-2 w-max">
            <BackArrow />
            <Text size="1.6rem" color="#6366F1">
                {title}
            </Text>
            {subTitle && <Text size="1.6rem">{subTitle}</Text>}
            {children}
        </FlexCentredRow>
    );
};
