import { toast } from 'react-toastify';

import { axiosInstance, currentAPI } from '../../config';

import { GET_ADMISSIONS_SESSIONS, LOADING, STOP_LOADING, GET_ACTIVE_SESSION, GET_ACTIVE_SEMESTER } from './slice';

export const getAdmissionSessions = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/all-session`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const sortedSessions = data?.slice()?.sort((a, b) => b?.admissionYear?.localeCompare(a?.admissionYear));
            dispatch(
                GET_ADMISSIONS_SESSIONS(
                    sortedSessions.map((item) => ({ ...item, admissionYear: item.academicSession })),
                ),
            );

            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred while getting sessions.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSession = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/active-session`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SESSION(data.admissionYear));
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSemester = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/active-semester`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SEMESTER(data.semester));
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
