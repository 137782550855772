import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    background-color: ${({ bgColor }) => bgColor || null};
    border-radius: ${({ radius }) => radius || null};
    border: ${({ border }) => border || null};
    padding: ${({ padding }) => padding || null};
    width: ${({ width }) => width || null};
`;

export const CentredBox = styled(Container)(() => [
    `
    flex-direction: ${({ direction }) => direction || 'row'};
    `,
    tw`
    flex
    items-center
    `,
]);

export const CenteredContainer = tw(Container)`
    flex
    justify-center
    items-center
`;

export const FlexCentredRow = tw(Container)`
  flex
  flex-row
  items-center
`;

export const FlexCentredCol = tw(Container)`
  flex
  flex-col
  justify-center
`;

export const FlexRowSpaceBetween = tw(FlexCentredRow)`
  justify-between
`;

export const FlexRowCentered = tw(FlexCentredRow)`
  justify-center
`;
export const FlexColCentered = tw(FlexCentredCol)`
  items-center
`;

export const ItemsPerPageCont = styled.div(() => [
    `
  `,
    tw`
  flex
  items-center
  flex-row
  justify-between
  px-[2.4rem]
  mt-[2rem]
  `,
]);

export const FlexDiv = tw(Container)`
    flex
    flex-row
`;

export const FlexGap = tw.div`
   flex
   gap-60
`;

export const FlexGap2 = tw.div`
   flex
   gap-12
`;

export const FlexMarg = tw.div`
   flex
   mr-12
`;

export const FlexColumn = tw(Container)`
    flex
    flex-col
`;

export const FlexRowEnd = tw(Container)`
    flex
    items-center
    justify-end
`;

export const NewCol = styled.div`
    color: ${({ col }) => col || '#059669'};
    font-style: ${({ fons }) => fons || 'normal'};
    font-weight: ${({ fonw }) => fonw || '500'};
    font-size: ${({ fonsi }) => fonsi || '1.4rem'};
    line-height: ${({ lin }) => lin || '2.4rem'};
    background-color: ${({ bgCols }) => bgCols || '#ECFDF5'};
    border-radius: ${({ bord }) => bord || '4px'};
    width: ${({ wid }) => wid || '65px'};
    height: ${({ bord }) => bord || '32px'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 4px;
`;

export const CheBo = styled.div`
    margin-top: ${({ mt }) => mt || '1.4rem'};
    display: flex;
    gap: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
`;

export const Acc = styled.div`
    color: #2563eb;
`;

export const RelativeContainer = tw.div`
  relative
`;
