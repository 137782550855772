import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    exeat: [],
    exeatDetails: {},
};

const exeatSlice = createSlice({
    name: 'exeat',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_EXEAT: (state, action) => {
            state.exeat = action.payload;
        },
        GET_EXEAT_BY_ID: (state, action) => {
            state.exeatDetails = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_EXEAT, GET_EXEAT_BY_ID } = exeatSlice.actions;

export default exeatSlice.reducer;
