import React from 'react';

export default function ModalOverlay({ openModal, onClose, children }) {
    if (!openModal) return null;

    return (
        <div onClick={onClose} className="bg-[rgba(0,0,0,0.7)] fixed inset-0 z-1">
            <div className="flex h-screen justify-center items-center z-[1000]">{children}</div>
        </div>
    );
}
