import tw, { styled } from 'twin.macro';

const TwButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
`;

export const LoaderDiv = styled.div`
    border: 4px solid #e5e7eb;
    border-top: 4px solid #6366f1;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
    margin-left: 0.5rem;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Button = styled(TwButton)`
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    color: ${({ color }) => color || '#1F2937'};
    border-radius: ${({ radius }) => radius || '4px'};
    border-color: ${({ bdColor }) => bdColor || 'null'};
    border: ${({ border }) => border || 'none'};
    outline: none !important;
    font-size: ${({ size }) => size || '1.4rem'};
    height: ${({ height }) => height || '4rem'};
    // don't do this - it breaks all previous uses
    /* width: ${({ width }) => width || '2rem'}; */
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    font-weight: ${({ weight }) => weight || 500};
    line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
    padding-top: ${({ paddingTop }) => paddingTop || null};
    padding-bottom: ${({ paddingBottom }) => paddingBottom || null};
    padding-left: ${({ paddingLeft }) => paddingLeft || null};
    padding-right: ${({ paddingRight }) => paddingRight || null};

    @media screen and (max-width: 768px) {
        /* Adjust styles for screens with a width up to 768 pixels */
        font-size: ${({ size }) => size || '1.2rem'};
        padding-top: ${({ mobilePaddingTop }) => mobilePaddingTop || null};
        padding-bottom: ${({ mobilePaddingBottom }) => mobilePaddingBottom || null};
        padding-left: ${({ mobilePaddingLeft }) => mobilePaddingLeft || null};
        padding-right: ${({ mobilePaddingRight }) => mobilePaddingRight || null};
    }

    &:disabled {
        opacity: 0.5;
    }
`;

export const SecondaryButton = styled(Button)`
    &:hover {
        /* background-color: #f9fafb; */
        background-color: darken;
    }
    &:focus {
        border: 2px solid #c7d2fe !important;
    }
    &:active {
        background-color: #e5e7eb;
    }
`;

export const LoadingButton = ({ loading, children, bgColor = '#6366F1', color = '#ffffff', className, ...props }) => (
    <Button bgColor={bgColor} color={color} {...props} className={className}>
        {children}
        {loading && <LoaderDiv />}
    </Button>
);
