import React from 'react';

import { ReactComponent as SpinnerIcon } from '../../assets/icons/loader.svg';

const Spinner = () => {
    return (
        <div className="w-[100%] h-screen bg-white z-[60] flex flex-col gap-[1rem] justify-center items-center">
            <SpinnerIcon className="w-[8rem] h-[8rem] animate-spin" />
        </div>
    );
};

export default Spinner;
