import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    error: null,
    lecturerNotice: [],
    adminNotice: [],
    bulletin: [],
    currentNotice: {},
    allReceived: {},
};

const communicationSlice = createSlice({
    name: 'communication',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_LECTURER_NOTICE: (state, action) => {
            state.lecturerNotice = action.payload;
        },
        GET_ADMIN_NOTICE: (state, action) => {
            state.adminNotice = action.payload;
        },
        GET_ALL_RECEIVED: (state, action) => {
            state.allReceived = action.payload;
        },
        GET_BULLETIN: (state, action) => {
            state.bulletin = action.payload;
        },
        SAVE_CURRENT_NOTICE: (state, action) => {
            state.currentNotice = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_BULLETIN,
    GET_ALL_RECEIVED,
    GET_LECTURER_NOTICE,
    GET_ADMIN_NOTICE,
    SAVE_CURRENT_NOTICE,
} = communicationSlice.actions;

export default communicationSlice.reducer;
