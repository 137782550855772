import React from 'react';

import { Field, ErrorMessage } from 'formik';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ClosedEyeIcon } from '../../../assets/icons/closed-eye.svg';
import { ReactComponent as OpenEyeIcon } from '../../../assets/icons/open-eye.svg';

const IconBox = tw.div`
    absolute
    right-[20px]
    z-10
    cursor-pointer
`;

const InputField = tw(Field)`
    pl-3
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    text-[#1F2937]
`;

const StyledInputField = styled(InputField)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
    // -webkit-appearance: none;
`;

const TextInput = ({
    as,
    type,
    children,
    value,
    name,
    errors,
    placeholder,
    className,
    width,
    height,
    onChange,
    inputType,
    togglePassword,
    label,
    color,
    autoCapitalize = 'off',
}) => {
    return (
        <div className="flex flex-col">
            {label && <label className={`font-medium text-[1.4rem] text-[${color || '#1F2937'}]`}>{label}</label>}
            <div className="flex items-center justify-between border-[1px] border-[#E5E7EB] rounded">
                <StyledInputField
                    type={type}
                    as={as}
                    placeholder={placeholder}
                    className={`${errors[name] && 'focus:ring-[#EF4444]'} ${className}`}
                    value={value}
                    name={name}
                    height={height}
                    width={width}
                    onChange={onChange && onChange}
                    autoCapitalize={autoCapitalize}
                >
                    {children}
                </StyledInputField>
                {inputType === 'password' && (
                    <IconBox onClick={togglePassword}>
                        {type === 'text' ? <OpenEyeIcon fill="#D1D5DB" /> : <ClosedEyeIcon />}
                    </IconBox>
                )}
            </div>
            <ErrorMessage name={name}>
                {(errorMessage) => <span className="text-[#EF4444] text-[1.2rem]">{errorMessage}</span>}
            </ErrorMessage>
        </div>
    );
};

export default TextInput;
