import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    error: null,
    discussions: [],
    registeredCourses: [],
};

const discussionSlice = createSlice({
    name: 'discussion',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_REGISTERED_COURSES: (state, action) => {
            state.registeredCourses = action.payload;
        },
        GET_ALL_DISCUSSIONS: (state, action) => {
            state.discussions = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_ALL_DISCUSSIONS, GET_REGISTERED_COURSES } = discussionSlice.actions;

export default discussionSlice.reducer;
