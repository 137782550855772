import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Dropdown2 } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as DoubleArrowLeft } from '../../../assets/icons/double-arrow-left.svg';
import { ReactComponent as DoubleArrowRight } from '../../../assets/icons/double-arrow-right.svg';
import { ReactComponent as EmptyStateMobile } from '../../../assets/icons/empty-mobile.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/single-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/single-arrow-right.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { getHandbook } from '../../../redux/handbook/action';

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #4b5563;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
    font-weight: 600;
`;

const HandbookMobile = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const { sessions, activeSession } = useSelector((state) => state.session);
    const [session, setSession] = useState(activeSession || '');
    const [openDropdown, setOpenDropdown] = useState('');

    const sessionItems = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const formatDate = (dateTimeString) => {
        const [datePart, timePart] = dateTimeString.split('T');
        return `${datePart} ${timePart.split('.')[0]}`;
    };
    const truncateDescription = (description, maxLength) => {
        if (description.length <= maxLength) return description;
        return description.slice(0, maxLength) + '...';
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page
    };

    useEffect(() => {
        if (session && session !== '') {
            dispatch(getHandbook(session));
        }
    }, [dispatch, session]);

    const paginatedData = data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const totalPages = Math.ceil(data?.length / itemsPerPage);

    return (
        <div className="pt-[1rem] h-page-height md:hidden flex flex-col">
            {!data?.length > 0 ? (
                <div className="flex flex-col items-center justify-center">
                    <EmptyStateMobile />
                    <Text size="2rem" weight="500" color="#172B4D" top="3.2rem">
                        No Handbook
                    </Text>
                    <Text size="1.6rem" color="#97A0AF" top="1.2rem">
                        Your handbook will show here
                    </Text>
                </div>
            ) : (
                <>
                    <div className="bg-white p-[1.6rem] rounded-t-lg flex justify-between items-center gap-[1rem]">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown2 className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={sessionItems}
                            />
                        </RelativeContainer>
                    </div>

                    {paginatedData?.map((item) => (
                        <div key={item?._id} className="bg-white flex flex-col p-[1.6rem] mt-[0.3rem]">
                            <PageTitle
                                align="left"
                                color="#2563EB"
                                onClick={() =>
                                    navigate(`/handbook/${item?._id}`, {
                                        state: {
                                            session: session,
                                            details: item,
                                            id: item?._id,
                                        },
                                    })
                                }
                            >
                                {' '}
                                {item?.title}
                            </PageTitle>
                            <Text align="left" size="1.4rem" weight="500" color="#6B7280">
                                {truncateDescription(item?.description, 20)}
                            </Text>
                            <FlexCentredRow className="gap-[4rem] mt-[1.5rem]">
                                <RowContainer className="">
                                    <FlexCol>
                                        <DarkText>Last Updated</DarkText>
                                        <GreyText>{formatDate(item?.updatedAt)}</GreyText>
                                    </FlexCol>
                                </RowContainer>
                                <RowContainer className="">
                                    <FlexCol>
                                        <DarkText>File Size</DarkText>
                                        <GreyText>{item?.handbookUpload?.[0]?.size}</GreyText>
                                    </FlexCol>
                                </RowContainer>
                            </FlexCentredRow>
                        </div>
                    ))}
                    <div className="flex flex-col justify-between mt-[0.3rem] bg-white p-[3rem]">
                        <FlexRowSpaceBetween>
                            <GreyText htmlFor="itemsPerPage">Items per page:</GreyText>
                            <select
                                id="itemsPerPage"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                className="border border-gray-300 rounded px-3 py-3 text-gray-600"
                            >
                                <option value={1}>1</option>
                                <option value={5}>10</option>
                                <option value={15}>15</option>
                            </select>
                        </FlexRowSpaceBetween>

                        <div className="flex justify-between mt-[2rem]">
                            <Button
                                onClick={() => handlePageChange(1)}
                                disabled={currentPage === 1}
                                className="px-3 py-1 mx-1 text-gray-700 bg-white border border-gray-300 rounded "
                            >
                                <DoubleArrowLeft className="fas fa-angle-double-left"></DoubleArrowLeft>
                            </Button>
                            <Button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-3 py-1 mx-1 text-gray-700 bg-white border border-gray-300 rounded "
                            >
                                <ArrowLeft className="fas fa-chevron-left"></ArrowLeft>
                            </Button>
                            {Array.from({ length: totalPages }, (_, index) => {
                                if (
                                    index + 1 === 1 ||
                                    index + 1 === totalPages ||
                                    (index + 1 >= currentPage - 1 && index + 1 <= currentPage + 1)
                                ) {
                                    return (
                                        <Button
                                            key={index + 1}
                                            onClick={() => handlePageChange(index + 1)}
                                            bgColor={currentPage === index + 1 ? '#EB4898' : '#fff'}
                                            color={currentPage === index + 1 ? '#fff' : '#1F2937'}
                                        >
                                            {index + 1}
                                        </Button>
                                    );
                                }
                                return null;
                            })}
                            <Button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="px-3 py-1 mx-1 text-gray-700 bg-white border border-gray-300 rounded "
                            >
                                <ArrowRight className="fas fa-chevron-right"></ArrowRight>
                            </Button>
                            <Button
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                                className="px-3 py-1 mx-1 text-gray-700 bg-white border border-gray-300 rounded "
                            >
                                <DoubleArrowRight className="fas fa-angle-double-right"></DoubleArrowRight>
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default HandbookMobile;
