import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allFees: [],
    fee: {},
    acceptanceFee: {},
    paymentDetails: {},
    paymentHistory: [],
    paymentReceipt: {},
    installmentPayment: [],
    currentFee: null,
    isVerifying: false,
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_FEES: (state, action) => {
            state.allFees = action.payload;
        },
        FEE: (state, action) => {
            state.fee = action.payload;
        },
        GET_ACCEPTANCE_FEE: (state, action) => {
            state.acceptanceFee = action.payload;
        },
        INITIATE_PAYMENT: (state, action) => {
            state.paymentDetails = action.payload;
        },
        GET_PAYMENT_HISTORY: (state, action) => {
            state.paymentHistory = action.payload;
        },
        GET_PAYMENT_RECEIPT: (state, action) => {
            state.paymentReceipt = action.payload;
        },
        GET_INSTALLMENT_FEES: (state, action) => {
            state.installmentPayment = action.payload;
        },
        GET_CURRENT_FEE: (state, action) => {
            state.currentFee = action.payload;
        },
        VERIFYING: (state, action) => {
            state.isVerifying = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALL_FEES,
    FEE,
    GET_ACCEPTANCE_FEE,
    INITIATE_PAYMENT,
    GET_PAYMENT_HISTORY,
    GET_PAYMENT_RECEIPT,
    GET_INSTALLMENT_FEES,
    GET_CURRENT_FEE,
    VERIFYING,
} = paymentSlice.actions;

export default paymentSlice.reducer;
