import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { TableProvider } from './contexts/tableContext';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

import './index.css';
import '@belrald_hq/belrald-ui/dist/style.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import './css/pills.css';
// import './css/table.css';
import './css/tabs.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ContextProvider>
                    <TableProvider>
                        <App />
                    </TableProvider>
                </ContextProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
