import React from 'react';

export default function ModalLibrary({ openRegulationModal, onRegulationClose, children }) {
    if (!openRegulationModal) return null;

    return (
        <div onClick={onRegulationClose} className="bg-[rgba(0,0,0,0.7)] fixed overflow-y-scroll inset-0 z-1">
            <div className="flex h-auto justify-center items-center z-[1000]">{children}</div>
        </div>
    );
}
