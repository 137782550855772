import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import arrowDownIcon from '../assets/icons/arrow-down.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import menuIcon from '../assets/icons/menu.svg';
import { ReactComponent as MenuMobile } from '../assets/icons/MenuMobile.svg';
import { ActionsPopup } from '../components';
import { RelativeContainer } from '../containers/ScreenContainers';
import { useContextProvider } from '../contexts/ContextProvider';
import { capitalizeFirstLetter } from '../utils';

import MobileMenu from './mobileMenu';

const Header = ({ open, setOpen, showMobileMenu, closeMobileMenu, setShowMobileMenu }) => {
    const { student } = useSelector((store) => store.student);

    const { handleClicked } = useContextProvider();

    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="">
            <div className="w-full top-0 right-0 left-0 sticky px-[1rem] md:px-[5rem] bg-white">
                <div className="flex items-start">
                    <div className="md:hidden">
                        {showMobileMenu && (
                            <MobileMenu student={student} open={showMobileMenu} close={closeMobileMenu} id="menu" />
                        )}
                    </div>

                    <header className="flex justify-between items-center w-full">
                        <div>
                            <MenuMobile
                                className="md:hidden cursor-pointer"
                                onClick={() => setShowMobileMenu(!showMobileMenu)}
                            />
                            <img
                                className="hidden md:flex cursor-pointer"
                                src={menuIcon}
                                alt="menu"
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                        <div className="md:hidden">
                            {student?.organizationLogo ? (
                                <img
                                    src={student?.organizationLogo}
                                    alt="logo"
                                    className="h-[4.8rem] w-[4.8rem] rounded-full"
                                />
                            ) : (
                                // <h1
                                //     className={`text-blue-sidenav items-center flex justify-center rounded-full w-[2.5rem] bg-white text-[2rem] text-center ml-[13.32px] font-semibold`}
                                // >
                                //     {student?.organizationName.charAt(0)}
                                // </h1>
                                ''
                            )}
                        </div>
                        <div className="flex justify-center items-center">
                            <div onClick={() => setShowMenu(!showMenu)} className="">
                                <RelativeContainer>
                                    <button className="relative w-max py-[4px] md:pr-[16px] md:pl-[4px] h-[4rem] rounded-[3.2rem] bg-light-ash flex items-center justify-center">
                                        <div className="px-[0.8rem] bg-avatar-bg w-[3.2rem] h-[3.2rem] rounded-full relative mr-2">
                                            <h4 className="uppercase text-avatar-text absolute w-[inherit] h-[auto] text-center tracking-wide leading-[1.8rem] top-2/4 right-[0.5px] -translate-y-2/4 text-[1.2rem]">
                                                {student ? `${student.firstName}`.charAt(0) : ''}
                                            </h4>
                                        </div>
                                        <div className="hidden md:flex items-center justify-center gap-[1rem]">
                                            <h4 className="text-[#2E4457] mt-[.8rem] font-medium text-[1.4rem] capitalize">
                                                {student
                                                    ? `${capitalizeFirstLetter(
                                                          student.firstName,
                                                      )} ${capitalizeFirstLetter(student.lastName)}`
                                                    : ''}
                                            </h4>

                                            <img src={arrowDownIcon} alt="dropdown" className="hidden md:block" />
                                        </div>
                                    </button>
                                    <ActionsPopup
                                        open={showMenu}
                                        className="z-10"
                                        close={() => setShowMenu(false)}
                                        items={[
                                            {
                                                icon: <LogoutIcon />,
                                                name: 'Logout',
                                                click: () => {
                                                    handleClicked('logout');
                                                    setShowMenu(false);
                                                },
                                            },
                                        ]}
                                    />
                                </RelativeContainer>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        </div>
    );
};

export default Header;
