import { styled } from 'twin.macro';

import { ReactComponent as ExcelIcon } from '../assets/icons/excel.svg';
import { ReactComponent as MSWordIcon } from '../assets/icons/microsoft-word.svg';
import { ReactComponent as PDFIcon } from '../assets/icons/pdf-icon.svg';
import { ReactComponent as PPTXIcon } from '../assets/icons/pptx-icon.svg';
import { Text } from '../containers/MesssageContainers';

export const generateWeeklyEvents = (data, weekCount = 13) => {
    const events = [];
    const startDate = new Date(data.startTime);
    const endDate = new Date(data.endTime);

    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();

    const weekdayNumber = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
        data.weekday,
    );

    const currentDate = new Date(startDate);
    for (let i = 0; i < weekCount; i++) {
        const weekday = currentDate.getDay();
        const daysUntilWeekday = (weekdayNumber + 7 - weekday) % 7;
        const date = new Date(currentDate.getTime());
        date.setDate(date.getDate() + daysUntilWeekday);
        if (date) {
            const startTime = new Date(date);
            startTime.setHours(startHours, startMinutes, 0);
            const endTime = new Date(date);
            endTime.setHours(endHours, endMinutes, 0);
            const event = {
                title: data.course,
                start: new Date(startTime),
                end: new Date(endTime),
            };
            events.push(event);
        }
        currentDate.setDate(currentDate.getDate() + 7);
    }

    return events;
};

export const sortData = (newData, date = 'createdAt') => {
    const sortedData = [...newData];
    sortedData?.sort((a, b) => new Date(b?.[date]) - new Date(a?.[date]));

    return sortedData;
};

export const formatDate = (givenDate) => {
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - new Date(givenDate).getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0) {
        return new Date(givenDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
        return new Date(givenDate).toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
};

export const addLabelsToData = (data) => {
    const combinedArray = [];

    if (!data) return;
    const addLabel = (dataArray, label) => {
        dataArray?.forEach((item) => {
            const itemWithLabel = { ...item, label };
            combinedArray.push(itemWithLabel);
        });
    };

    addLabel(data?.bulletins, 'Bulletin');
    addLabel(data?.notices?.lecturer, 'Lecturer Notice');
    addLabel(data?.notices?.admin, 'Admin Notice');

    return combinedArray;
};

export const getLabelBadge = (label) => {
    let bgColor, textColor, labelText;

    switch (label) {
        case 'Admin Notice':
            bgColor = '#FDF2F8';
            textColor = '#EB4898';
            labelText = label;
            break;
        case 'Lecturer Notice':
            bgColor = '#ECFDF5';
            textColor = '#10B981';
            labelText = label;
            break;
        case 'Mail':
            bgColor = '#EFF6FF';
            textColor = '#3B82F6';
            labelText = label;
            break;
        case 'External Memo':
            bgColor = '#F5F3FF';
            textColor = '#EB4898';
            labelText = label;
            break;
        case 'Memo':
            bgColor = '#EFF6FF';
            textColor = '#3B82F6';
            labelText = label;
            break;
        case 'Letter':
            bgColor = '#F5F3FF';
            textColor = '#EB4898';
            labelText = label;
            break;
        default:
            bgColor = '#F5F3FF';
            textColor = '#8B5CF6';
            labelText = label;
            break;
    }

    const StatusContainer = styled.div`
        padding: 2px 4px;
        border-radius: 4px;
        background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
        width: fit-content;
    `;

    return (
        <StatusContainer bgColor={bgColor}>
            <Text color={textColor} weight="500" size="1.2rem">
                {labelText}
            </Text>
        </StatusContainer>
    );
};

export const scrollToTop = (ref) => {
    ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });
};

export const capitalizeFirstLetter = (str = '') => {
    // e.g 'let me leave' => Let Me Leave OR LET => Let
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

export const encodeQuery = (queryObj) => {
    const encodedQuery = Object.entries(queryObj).map(([key, value]) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString());
    });
    return encodedQuery.join('&');
};

export const getFileIcon = (fileName) => {
    if (!fileName) return;
    if (fileName?.includes('xls')) return <ExcelIcon height={24} width={24} />;
    if (fileName?.includes('ppt')) return <PPTXIcon />;
    if (fileName?.includes('pdf')) return <PDFIcon />;
    if (fileName?.includes('doc') || fileName.includes('docx')) return <MSWordIcon />;
    return null;
};
