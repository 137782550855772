import React, { useState, useEffect } from 'react';

import mammoth from 'mammoth';
import { Document, Page, pdfjs } from 'react-pdf';
import * as XLSX from 'xlsx';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey-sm.svg';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-icon.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { Button } from '../buttons';
import Spinner from '../spinner';

const FilePreview = ({ url = '', onClose, fileType = 'pdf', fileName, mobile, pdfTitle }) => {
    const [numPages, setNumPages] = useState([]);
    const [scale, setScale] = useState(1.0);
    const [pageNumber, setPageNumber] = useState(1);
    const [docContent, setDocContent] = useState('');
    const [excelData, setExcelData] = useState([]);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const handleZoomIn = () => setScale((prevScale) => prevScale + 0.25);
    const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.25, 0.25));

    useEffect(() => {
        // Handle .docx files
        if (fileType === 'docx') {
            fetch(url)
                .then((res) => res.arrayBuffer())
                .then((buffer) => mammoth.extractRawText({ arrayBuffer: buffer }))
                .then((result) => setDocContent(result.value))
                .catch((error) => console.error('Error loading docx file:', error));
        }
        // Handle .xlsx files
        else if (fileType === 'xlsx') {
            fetch(url)
                .then((res) => res.arrayBuffer())
                .then((buffer) => {
                    const workbook = XLSX.read(buffer, { type: 'array' });
                    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    setExcelData(XLSX.utils.sheet_to_json(firstSheet, { header: 1 }));
                })
                .catch((error) => console.error('Error loading xlsx file:', error));
        }
    }, [url, fileType]);

    const renderContent = () => {
        switch (fileType) {
            case 'pdf':
                return (
                    <Document
                        className="flex items-center justify-center flex-col"
                        file={url}
                        onLoadSuccess={(pdf) => {
                            setNumPages(pdf.numPages);
                            setPageNumber(1);
                        }}
                        loading={<Spinner />}
                    >
                        {Array.from(new Array(numPages), (el, index) => index + 1).map((page) => (
                            <Page
                                key={`page_${page}`}
                                pageNumber={page}
                                scale={scale}
                                width={mobile ? 350 : 850}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>
                );
            case 'docx':
                return <div dangerouslySetInnerHTML={{ __html: docContent }} />;
            case 'xlsx':
                return (
                    <table>
                        <tbody>
                            {excelData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                );
            case 'pptx':
                return <p>Preview for .pptx files is not supported yet.</p>;
            case 'image':
                return <img src={url} alt={fileName} className="max-w-full max-h-[70vh]" />;
            default:
                return <p>Unsupported file format</p>;
        }
    };

    return (
        <div className="relative">
            <FlexRowSpaceBetween className="border-b py-[2rem] px-[2rem] gap-[1rem] md:gap-[3rem] shadow-md">
                <FlexCentredRow className="gap-4">
                    {fileType === 'pdf' && <PdfIcon />}
                    <Text weight="600" size="1.9rem" lineHeight="2.8rem">
                        {fileName || pdfTitle}
                    </Text>
                </FlexCentredRow>

                <FlexCentredRow className="gap-[0.3rem] md:gap-[1rem]">
                    {fileType === 'pdf' && (
                        <>
                            <Button weight="600" bgColor="[#E7E2E2]">
                                {pageNumber}
                            </Button>
                            <Text>/</Text>
                            <Text weight="600">{numPages}</Text>
                        </>
                    )}
                </FlexCentredRow>

                {fileType === 'pdf' && (
                    <FlexCentredRow className="gap-[0.3rem] md:gap-[1rem]">
                        <Button size="2rem" weight="600" onClick={handleZoomOut} disabled={scale >= 2.0}>
                            -
                        </Button>
                        <Button size="2rem" weight="600" onClick={handleZoomIn} disabled={scale >= 0.8}>
                            +
                        </Button>
                    </FlexCentredRow>
                )}

                {onClose && (
                    <FlexCentredRow>
                        <CloseIcon className="cursor-pointer" onClick={onClose} />
                    </FlexCentredRow>
                )}
            </FlexRowSpaceBetween>

            <div className="flex justify-center items-center p-4">{renderContent()}</div>
        </div>
    );
};

export default FilePreview;
