import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth: {},
    loading: false,
    error: null,
    isLoggedIn: false,
    showBanner: true,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.loading = true;
        },
        STOP_LOADING: (state) => {
            state.loading = false;
        },
        login: (state, action) => {
            state.auth = action.payload;
            state.loading = true;
            state.isLoggedIn = true;
            localStorage.setItem('auth', JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.auth = initialState.auth;
            state.loading = false;
            state.isLoggedIn = false;
            localStorage.removeItem('auth');
        },
        TOGGLE_BANNER_VIEW: (state, action) => {
            state.showBanner = action.payload;
        },
    },
});

export const { login, logout, LOADING, STOP_LOADING, TOGGLE_BANNER_VIEW } = authSlice.actions;

export default authSlice.reducer;
