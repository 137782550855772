import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    error: null,
    courseResources: [],
};

const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_COURSE_RESOURCES: (state, action) => {
            state.courseResources = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_COURSE_RESOURCES } = resourcesSlice.actions;

export default resourcesSlice.reducer;
