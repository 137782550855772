import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Close } from '../assets/icons/close-white.svg';
import { Text } from '../containers/MesssageContainers';
import { FlexRowEnd } from '../containers/ScreenContainers';

import { navLinks } from './navLinks';

const MobileMenu = ({ close, id, open, student }) => {
    const location = useLocation();

    return (
        <div className="relative z-10" id={id}>
            {open && <div className="fixed z-10 inset-0" onClick={close}></div>}
            <nav
                className="w-full flex flex-col items-start gap-[1.6rem] px-[4rem] left-0 inset-0 z-[10] fixed
               duration-300 h-screen pb-[2rem] bg-blue-sidenav overflow-y-scroll scrollbar-hide"
            >
                <FlexRowEnd className="ml-[auto] pt-[2rem] pl-[2.5rem] pb-[1.6rem]">
                    <Close onClick={close} />
                </FlexRowEnd>
                <div className="w-[auto] flex items-center gap-[1.6rem] justify-center">
                    <div className="flex items-center justify-center bg-avatar-bg w-[4.8rem] h-[4.8rem] rounded-full relative">
                        <Text size="1.9rem" color="#6366F1" weight="500">
                            {student.firstName.charAt(0)}
                        </Text>
                        <Text size="1.9rem" color="#6366F1" weight="500">
                            {student.lastName.charAt(0)}
                        </Text>
                    </div>
                    <div className="flex flex-col items-start gap-[0.8rem]">
                        <Text color="#FFFFFF" weight="600" size="1.6rem" lineHeight="2.8rem">
                            {student ? `${student.firstName} ${student.lastName}` : ''}
                        </Text>
                        <Text color="#FFFFFF" size="1.6rem" weight="400" lineHeight="2.8rem">
                            {student.matricNumber || ''}
                        </Text>
                    </div>
                </div>
                <ul className="text-light-ash-bg">
                    {navLinks?.map((link, index) => {
                        const isActive =
                            location?.pathname === link?.to ||
                            (location.pathname.startsWith(link?.to) && link?.to !== '/');
                        return (
                            <Link to={link?.to} key={index} onClick={close}>
                                <li
                                    className={`font-medium text-[1.4rem] leading-6 flex items-center gap-x-4 cursor-pointer ${
                                        isActive ? 'bg-white' : ''
                                    } hover:bg-[#4F46E5] focus:bg-white rounded-[8px] px-[1.315rem] pt-[13.98px] pb-[14.15px]`}
                                >
                                    {isActive ? link?.active : link?.src}
                                    <span className={` ${isActive ? 'text-[#1F2937]' : 'text-light-ash-bg'} `}>
                                        {link?.title}
                                    </span>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default MobileMenu;
