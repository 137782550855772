import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    lectureTimetable: [],
    examTimetable: [],
    acadCalendar: [],
};

export const timetableSlice = createSlice({
    name: 'timetable',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_LECTURE_TIMETABLE: (state, action) => {
            state.lectureTimetable = action.payload;
        },
        GET_EXAM_TIMETABLE: (state, action) => {
            state.examTimetable = action.payload;
        },
        GET_ACADEMIC_CALENDAR: (state, action) => {
            state.acadCalendar = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_LECTURE_TIMETABLE, GET_EXAM_TIMETABLE, GET_ACADEMIC_CALENDAR } =
    timetableSlice.actions;

export default timetableSlice.reducer;
