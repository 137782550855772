import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
// import Cookies from "universal-cookie"

const AuthGuard = () => {
    // const cookies = new Cookies();
    const { student } = useSelector((store) => store.student);
    let accessToken = student.token;
    return accessToken ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthGuard;
