import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as CircularMark } from '../../../assets/icons/circular-mark.svg';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { Button } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';

const UploadSuccess = ({ onClose }) => {
    const navigate = useNavigate();

    return (
        <div className="w-[100vw] h-[100vh]  flex justify-center flex-col items-center fixed top-[0] bg-black/[0.5] backdrop-filter backdrop-blur-[2px] z-50">
            <div className="relative bg-[#ffffff] rounded-[8px] w-[592px] h-[494px] flex justify-center flex-col items-center gap-[30px]">
                <Close
                    className="ml-[auto] absolute top-[0] right-[0] mt-[30px] mr-[30px] cursor-pointer"
                    onClick={onClose}
                />
                <CircularMark />
                <Text
                    className="w-[113px] h-[52px] tracking-[-0.01em] "
                    size="40px"
                    weight="700"
                    color="#1F2937"
                    lineHeight="52px"
                >
                    {' '}
                    Done!
                </Text>
                <Text className="w-[359px] h-[56px]" color="#1F2937" weight="500" size="19px" lineHeight="28px">
                    {' '}
                    You have completed your Bio-data Registration(4).
                </Text>
                <Button
                    className="py-[10px] px-[20px] w-[100px] h-[80px] focus:outline-none focus:border-none"
                    type="button"
                    onClick={() => navigate('/dashboard')}
                    bgColor="#6366F1"
                    color="#ffffff"
                    radius="4px"
                >
                    {' '}
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default UploadSuccess;
