import { Suspense, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Spinner } from './components';
import DeletePopup from './components/popups/forum-delete';
import { useContextProvider } from './contexts/ContextProvider';
import Layout from './layout';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import { logoutUser } from './redux/auth/actions';
import { parseJSON } from './utils/useJSON';
import {
    SignInNewStudent,
    SignInReturningStudent,
    ForgotPassword,
    ConfirmPassword,
    LinkSentPopUp,
    NoAdmission,
    Acceptance,
    Invoice,
    // PaymentSuccessPopUp,
} from './views';

import 'react-datepicker/dist/react-datepicker.css';
import '@belrald_hq/belrald-ui/dist/style.css';

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isClicked, handleUnclicked } = useContextProvider();

    const [open, setOpen] = useState(true);

    const token = parseJSON('token', '');

    const Logout = () => {
        handleUnclicked('logout');
        dispatch(logoutUser(navigate));
    };

    return (
        <>
            <ToastContainer className="text-[1.6rem]" />
            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path="/sign-up" element={<SignInNewStudent />} />
                    <Route path="/login" element={<SignInReturningStudent />} />

                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/student/change-password/:token" element={<ConfirmPassword />} />
                    <Route path="/expired-link" element={<NoAdmission />} />
                    <Route
                        path="/payments/invoice"
                        element={
                            <div className={`${open ? 'md:ml-[25rem]' : 'md:ml-[8.4rem]'} ml-[0] flex w-100% h-screen`}>
                                <Sidebar open={open} setOpen={setOpen} />
                                <div className="flex flex-col w-[100%]">
                                    <Header open={open} setOpen={setOpen} />
                                    <main className="w-[100%] md:px-[5rem] overflow-y-none md:mt-[5rem] px-[2.5rem] pt-[1rem]">
                                        <Invoice />
                                    </main>
                                </div>
                            </div>
                        }
                    />
                    <Route
                        path="/link-sent-popup"
                        element={token ? <LinkSentPopUp /> : <Navigate to="/login" replace />}
                    />
                    <Route path="/acceptance" element={token ? <Acceptance /> : <Navigate to="/login" replace />} />
                    <Route path="*" element={<Layout />} />
                </Routes>

                {isClicked.logout && (
                    <DeletePopup
                        show={isClicked.logout}
                        title="Logging Out"
                        subtitle="Are you sure you want to log out?"
                        confirmText="Logout"
                        onConfirm={() => Logout()}
                        onCancel={() => handleUnclicked('logout')}
                    />
                )}
            </Suspense>
        </>
    );
}

export default App;
