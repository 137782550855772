import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import tw, { styled } from 'twin.macro';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-new.svg';
import { Spinner } from '../../../components';
import { GoBack } from '../../../components/go-back';
import PDFPreview from '../../../components/pdf-preview';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getHandbookById } from '../../../redux/handbook/action';
import { capitalizeFirstLetter } from '../../../utils';

const MainContainer = tw.div`
    mt-8
    mb-[6.4rem]
    rounded-lg
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;
const PdfDetails = tw.div`
    bg-white
    rounded-lg
    mt-[0.3rem]
    md:mt-[2rem]
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const HandbookDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, handbookDetails: details } = useSelector((store) => store.handbook);

    const url = window.location.href;

    const sessionParam = url?.split('=');

    const session = sessionParam[1];

    useEffect(() => {
        if (id && session) {
            dispatch(getHandbookById(id, session));
        }
    }, [dispatch, id, session]);

    const handleDownload = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            toast.error('Error occurred during download:', error);
        }
    };

    const responsive = window.innerWidth <= 769;
    const [isMobile, setIsMobile] = useState(responsive);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isLoading) return <Spinner />;

    return (
        <div>
            <div className="mb-[3.2rem]">
                <GoBack
                    title={isMobile ? '' : 'Handbook'}
                    subTitle={isMobile ? details?.title : `/ ${details?.title}`}
                ></GoBack>
            </div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexCentredRow>
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredRow>

                    {isMobile ? (
                        <>
                            <FlexRowSpaceBetween className="my-8">
                                <FlexCol>
                                    <GreyText>Title</GreyText>
                                    <DarkText>{details?.title}</DarkText>
                                </FlexCol>
                                <DownloadIcon
                                    onClick={() => {
                                        const downloadUrl = details?.handbookUpload?.[0]?.url;
                                        const fileName = details?.handbookUpload?.[0]?.fileName;

                                        if (downloadUrl && fileName) {
                                            handleDownload(downloadUrl, fileName);
                                        }
                                    }}
                                />
                            </FlexRowSpaceBetween>
                            <RowContainer className="">
                                <FlexCol>
                                    <GreyText>Description</GreyText>
                                    <DarkText>{details?.description}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </>
                    ) : (
                        <BasicContent>
                            <RowContainer className="">
                                <FlexCol>
                                    <GreyText>Title</GreyText>
                                    <DarkText>{details?.title}</DarkText>
                                </FlexCol>
                            </RowContainer>

                            <RowContainer className="">
                                <FlexCol>
                                    <GreyText>Description</GreyText>
                                    <DarkText>{details?.description}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer className="">
                                <DownloadIcon
                                    onClick={() => {
                                        const downloadUrl = details?.handbookUpload?.[0]?.url;
                                        const fileName = details?.handbookUpload?.[0]?.fileName;

                                        if (downloadUrl && fileName) {
                                            handleDownload(downloadUrl, fileName);
                                        }
                                    }}
                                />
                            </RowContainer>
                        </BasicContent>
                    )}
                </Details>
                <PdfDetails>
                    {details?.handbookUpload?.map((upload, i) => (
                        <div key={i}>
                            <PDFPreview
                                mobile={isMobile}
                                url={upload?.url}
                                pageNumber={1}
                                pdfTitle={capitalizeFirstLetter(details?.title)}
                            />
                        </div>
                    ))}
                </PdfDetails>
            </MainContainer>
        </div>
    );
};

export default HandbookDetails;
