import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { rootReducer } from './rootReducer';

const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['',]
    // whitelist: ['']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: process.env.REACT_APP_ENV !== 'development' || process.env.REACT_APP_ENV !== 'production',
});

export const persistor = persistStore(store);
