import React from 'react';

import { Field } from 'formik';
import tw, { styled } from 'twin.macro';

const Input = tw(Field)`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    text-[14px]
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
`;
const StyledInput = styled(Input)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
    border-radius: ${({ radius }) => radius || '4px'};
`;
const SelectInput = ({ placeholder, data, value, onChange, name, className, width, errors, height, radius }) => {
    return (
        <div>
            <StyledInput
                as="select"
                value={value}
                onChange={onChange && onChange}
                name={name}
                width={width}
                height={height}
                radius={radius}
            >
                <optgroup className="placeholder:text-[1.4rem]">
                    {placeholder ? <option value="">{placeholder}</option> : null}
                    {data?.map((item, index) => (
                        <option key={index} value={item}>
                            {item}
                        </option>
                    ))}
                </optgroup>
            </StyledInput>
            {/* <ErrorMessage name={name}>
                {(errorMessage) => <span className="text-[#EF4444] text-[1.2rem]">{errorMessage}</span>}
            </ErrorMessage> */}
        </div>
    );
};

export default SelectInput;
