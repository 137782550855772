import { createContext, useContext, useState } from 'react';

const TableContext = createContext(null);

export const TableProvider = ({ children }) => {
    const [tableSettings, setTableSettings] = useState(null);

    const saveTableSettings = (value) => {
        setTableSettings(value);
    };

    const values = { saveTableSettings, tableSettings };
    return <TableContext.Provider value={values}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);
