import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assignment: {},
    allAssignments: [],
    isLoading: false,
    error: null,
};

const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ASSIGNMENT: (state, action) => {
            state.assignment = action.payload;
        },
        GET_ALL_ASSIGNMENT: (state, action) => {
            state.allAssignments = action.payload;
        },
    },
});

export const { GET_ASSIGNMENT, GET_ALL_ASSIGNMENT, LOADING, STOP_LOADING } = assignmentSlice.actions;

export default assignmentSlice.reducer;
