import React from 'react';

import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search2.svg';

const Container = styled.div`
    position: relative;
    height: 40px;
    width: ${({ width }) => width || 'fit-content'};
`;

const Input = styled.input`
    width: ${({ width }) => width || '28.3rem'};
    border: 1px solid #e5e7eb;
    color: #1f2937;
    font-size: 1.4rem;
    font-weight: 500;
    padding: ${({ iconLeft }) => (iconLeft ? '8px 16px 8px 35px' : '8px 16px')};
    outline: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 100%;
    border-top-right-radius: ${({ withRadius }) => (withRadius ? '8px' : '')};
    border-bottom-right-radius: ${({ withRadius }) => (withRadius ? '8px' : '')};
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: ${({ iconLeft }) => (iconLeft ? '' : '20px')};
    left: ${({ iconLeft }) => (iconLeft ? '10px' : '')};
`;

const SearchInput = ({
    placeholder,
    value,
    onChange,
    withRadius,
    containerWidth,
    width,
    onSearch,
    onClose,
    onKeyDown,
    iconLeft = true,
}) => {
    return (
        <Container width={containerWidth}>
            <Input
                width={width}
                value={value}
                onChange={onChange}
                placeholder={placeholder || 'Search'}
                withRadius={withRadius}
                onKeyDown={onKeyDown}
                iconLeft={iconLeft}
            />
            <IconWrapper iconLeft={iconLeft}>
                <SearchIcon onClick={onSearch} className="cursor-pointer" />
            </IconWrapper>

            {value?.length > 0 && (
                <IconWrapper>
                    <CloseIcon onClick={onClose} className="cursor-pointer" />
                </IconWrapper>
            )}
        </Container>
    );
};

export default SearchInput;
