import { styled } from 'twin.macro';

import { Button, SecondaryButton } from '../buttons';

export const CancelButton = styled(SecondaryButton)`
    border: 0.5px solid #e5e7eb;
`;

export const SubmitButton = styled(Button)`
    background-color: #6366f1;
    color: #ffffff;
`;
