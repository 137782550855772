import { createContext, useContext, useState } from 'react';

const AppContext = createContext(null);

const initialState = { logout: false, installment: false };

export const ContextProvider = ({ children }) => {
    const [isClicked, setIsClicked] = useState(initialState);

    const handleClicked = (clicked) => {
        setIsClicked({ ...initialState, [clicked]: true });
    };

    const handleUnclicked = (clicked) => {
        setIsClicked({ ...initialState, [clicked]: false });
    };

    const values = { isClicked, handleClicked, handleUnclicked };

    return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useContextProvider = () => useContext(AppContext);
